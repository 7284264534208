<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 pa-0 ma-0">
      </div>
    </div>
    <div class="row">
      <div class="col-12 pa-0 ma-0">
        <md-card>
          <md-card-header :data-background-color="getTheme">
            <h4 class="title">{{$t('user.userTableTitle')}}</h4>
          </md-card-header>
          <md-card-content>
            <user-table :table-header-color="getTheme" />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserTable from "../../components/Users/UserTable";
import { mapGetters } from "vuex";
export default {
  components: {
    UserTable,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  data: () => ({
  }),
};
</script>