<template>
  <div class="crud-add-button">
    <div>
      <router-link :to="to">
        <span class="material-icons"> add_circle </span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        to: {required: true}
    }
}
</script>