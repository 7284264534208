<template>
  <div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <tgl-add-item-button :to="{ name: 'userCreate' }" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <datatables-wrapper
          @optionsChanged="getItems"
          @updateTable="getItems"
          :loading="loading"
          :data="data"
          :headers="headers"
          filterType="simple"
          :parameters="['name']"
          @rowClick="rowClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
import TglAddItemButton from "../UI/TglAddItemButton";
export default {
  name: "user-table",
  components: {
    TglAddItemButton,
    DatatablesWrapper,
  },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    data: {},
    loading: false,
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Apellido", value: "lastName" },
      { text: "Correo", value: "email" },
      { text: "Creación", value: "timestamp" },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("userV1/getUsers", options)
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "userProfile",
        params: { user: event.id },
      });
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>